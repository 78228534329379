<nb-layout>



  <nb-layout-column>

    
    <app-board></app-board>
    
    <router-outlet></router-outlet>
  </nb-layout-column>



</nb-layout>
