
<h2>Hello Stranger!</h2>


<h3>Current Player: {{player}}</h3>


<button nbButton outline status="danger" (click)="newGame()">Start New Game</button>


<h2 *ngIf="winner">
    Player {{winner}} won the game!
</h2>


<main>
    <app-square *ngFor="let val of squares; let i = index"
        [value]="val"
        (click)="makeMove(i)">

    </app-square>
</main>